import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Beach Volleyball Tournament",
  "featuredImage": "images/beach_volleyball_tournament.jpg",
  "coverAlt": "Beach Volleyball Tournament",
  "description": "Graphic Design for Serve Your Community Beach Volleyball Tournament",
  "date": "2015-06-04",
  "dateModified": "2015-06-04",
  "category": "portfolio",
  "pinned": false,
  "featured": false,
  "tags": ["Graphic Design"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A good friend was hosting an event to raise awareness and support a local Kamloops organization (Elizabeth Fry Society) that supports women and youth. He wanted a volleyball and sports themed poster for the event; this was the result.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Skills Used-----------`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Software Used-------------`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Graphic Design Logo Design Typography Vector design Photo manipulation Color correction`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Adobe Photoshop Adobe Illustrator`}</td>
        </tr>
      </tbody>
    </table>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "724px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/ebfa32cadac190d0817876ed0d6fd328/7a493/beach_volleyball_tournament.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "141.38888888888889%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDBAH/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAXjpWWgoPElHO9Rc0j//xAAaEAEAAgMBAAAAAAAAAAAAAAABAAIDESFB/9oACAEBAAEFAsowrCeV477wi6lUY3dOWxCf/8QAGBEBAQEBAQAAAAAAAAAAAAAAAQARIkL/2gAIAQMBAT8BxbF82cw3/8QAGxEAAwACAwAAAAAAAAAAAAAAAAERAhIhMUH/2gAIAQIBAT8B2S4ZZ6YzasfZ/8QAHBAAAgICAwAAAAAAAAAAAAAAAAEhQQIQESIx/9oACAEBAAY/AoSOqSZ4NE87goedla//xAAdEAACAgMAAwAAAAAAAAAAAAABEQAxIUFRYYGR/9oACAEBAAE/IUWg8s2J1R5cC2FwlqG9TCT4hyMTlmPEJ7gEktnsI1AMLgi2iISdXP/aAAwDAQACAAMAAAAQiB++/8QAGxEAAgIDAQAAAAAAAAAAAAAAAAERMUFhkfD/2gAIAQMBAT8QvrRcfnB4XAyLP//EABwRAAICAgMAAAAAAAAAAAAAAAERACFBgZHh8P/aAAgBAgEBPxAWy7wY9B7zGCN76ikip//EABsQAQEBAQEBAQEAAAAAAAAAAAERIQAxQWGx/9oACAEBAAE/EHJzUehk/OUlQNWA+m/3pmo0bwUwaGM4KmnzBxmBWznw08m2P3qhkDa96xJ9HrmjFkGpiB1ymLQdLhVOl7//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Beach Volleyball Tournament",
              "title": "Beach Volleyball Tournament",
              "src": "/static/ebfa32cadac190d0817876ed0d6fd328/7a493/beach_volleyball_tournament.jpg",
              "srcSet": ["/static/ebfa32cadac190d0817876ed0d6fd328/543cd/beach_volleyball_tournament.jpg 360w", "/static/ebfa32cadac190d0817876ed0d6fd328/20801/beach_volleyball_tournament.jpg 720w", "/static/ebfa32cadac190d0817876ed0d6fd328/7a493/beach_volleyball_tournament.jpg 724w"],
              "sizes": "(max-width: 724px) 100vw, 724px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Beach Volleyball Tournament`}</figcaption>{`
  `}</figure></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      